import React from "react";
import Footer from "../home/footer/Footer";
import Navbar from "../home/navbar/Navbar";
import ContactUs from "./contactUs";
import SEO from "../Seo";
import * as styles from "./contactUs.module.css";

const Index = () => {
  return (
    <div className={styles.componentWrapper}>
      <SEO titleStub={`Contact Us`} urlStub={`/contact-us/`} />
      <Navbar />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Index;
