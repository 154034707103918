import React from "react";
import * as styles from "./contactUs.module.css";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { GrTwitter } from "@react-icons/all-files/gr/GrTwitter";
import { GrMail } from "@react-icons/all-files/gr/GrMail";

const ContactUs = () => {
  return (
    <>
      <div className={styles.mainSection}>
        <div className={styles.contentWrap}>
          <div className={styles.content}>
            <h1>
              <span>Contact Sleepless Yogi</span>
            </h1>
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.contentWrap}>
          <div className={styles.content}>
            <p>Hi,</p>
            <p>
              Glad to see you here, if you are looking to contact me for any
              query, information and any services like advertising, guest
              posting, or consultation, etc.
            </p>
            <p>You can contact me using below information:</p>
            <div className={styles.footer_contact}>
              <div className={styles.footer_contact_info}>
                <a
                  href="https://www.instagram.com/sleeplessyogi"
                  target="_blank"
                  title="Instagram"
                >
                  <span>
                    <FaInstagram />
                  </span>
                  <p>Instagram</p>
                </a>
              </div>
              <div className={styles.footer_contact_info}>
                <a
                  href="https://twitter.com/sleeplessyogi"
                  target="_blank"
                  title="Twitter"
                >
                  <span>
                    <GrTwitter />
                  </span>
                  <p>Twitter</p>
                </a>
              </div>
              <div className={styles.footer_contact_info}>
                <a
                  href="mailto:sleeplessyogi@gmail.com"
                  target="_blank"
                  title="Email"
                >
                  <span>
                    <GrMail />
                  </span>
                  <p>sleeplessyogi@gmail.com</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
